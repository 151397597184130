import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout'
import gatsbyConfig, { siteMetadata } from '../../gatsby-config'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const {
    frontmatter,
    html,
    timeToRead,
    excerpt,
    metaDescription,
  } = markdownRemark
  const { title, subtitle, date, systemDate, slug, img } = frontmatter
  let featuredImgFluid = img ? img.childImageSharp.fluid : null
  let publicURL: string = img ? img.publicURL : null

  const actualTitle = `${title} — ${gatsbyConfig.siteMetadata.title}`
  return (
    <Layout>
      <Helmet>
        <title> {actualTitle} </title>
        <link rel="canonical" href={`${siteMetadata.siteUrl}/${slug}`} />
        <meta property="og:title" content={actualTitle} />
        <meta name="twitter:title" content={title} />
        <meta property="og:type" content="article" />
        <meta name="twitter:label1" content="Time To Read" />
        <meta name="twitter:data1" content={`${timeToRead} min`} />
        {publicURL && [
          <meta property="og:image" content={publicURL} />,
          <meta property="twitter:image" content={publicURL} />,
        ]}
        {date && (
          <meta property="article:published_time" content={systemDate} />
        )}
        {excerpt && <meta property="og:description" content={excerpt} />}
        {excerpt && <meta name="description" content={metaDescription} />}
      </Helmet>
      <div className="text-gray-500 text-sm pt-4">
        {date && `${date} · `}
        {timeToRead} min read
      </div>
      <h1 className="pt-1">{title}</h1>
      {subtitle && (
        <div className="text-gray-500 text-2xl font-semibold -mt-1 mb-2">
          {subtitle}
        </div>
      )}

      <Img className="mt-2" fluid={featuredImgFluid} />
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt(pruneLength: 500)
      metaDescription: excerpt(pruneLength: 200)
      frontmatter {
        slug
        date(formatString: "MMMM DD, YYYY")
        systemDate: date(formatString: "YYYY-MM-DDT09:14:00+00:00")
        title
        subtitle
        img {
          publicURL
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
